<template>
  <div class="container">
    <span id="loading" v-if="this.loading == true">
      <Loading />
    </span>
    <h1>Tempo de Envio</h1>
    <select
      class="form-select form-select"
      aria-label=".form-select-lg example"
      v-model="company"
      @change="getOrdersDays"
    >
      <option selected value="">Todas as transportadoras</option>
      <option value="ctt">CTT Normal</option>
      <option value="ctt expresso">CTT Expresso</option>
      <option value="dhl">DHL</option>
      <option value="fedex">Fedex</option>
    </select>
    <p>
      Média de dias até Expedição:
      <strong>{{ averageDaysReceiveToExpedition() }}</strong> | Média de
      Expedição até Entrega:
      <strong>{{ averageDaysExpeditionToReceived() }}</strong> | Média de dias
      Total: <strong>{{ averageTotalDays() }}</strong>
    </p>
    <b-table
      striped
      hover
      :items="orders"
      :fields="fields"
      :filter="filter"
      thead-class="text-blue"
    >
      <template #cell(date)="data">
        {{ splitDate(data.item.date) }}
      </template>
      <template #cell(date_delivered)="data">
        {{ splitDate(data.item.date_delivered) }}
      </template>
    </b-table>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Loading from "@/components/Loading.vue";
export default {
  components: {
    Loading,
  },
  data: function () {
    return {
      loading: false,
      orders: [],
      filter: null,
      company: "",
      fields: [
        {
          key: "order_id",
          label: "Encomenda",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle",
        },
        {
          key: "date",
          label: "Data da encomenda",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle",
        },
        {
          key: "date_label",
          label: "Data de Saída",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle",
        },
        {
          key: "date_delivered",
          label: "Data de entrega",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle",
        },
        {
          key: "receiveToExit",
          label: "Dias até expedição",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle",
        },
        {
          key: "exitToDeliver",
          label: "Dias da Expedição até entrega",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle",
        },
        {
          key: "totalDays",
          label: "Total de Dias",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle",
        },
      ],
    };
  },
  methods: {
    async getOrdersDays() {
      try {
        this.loading = true;
        console.log("AQUI");
        await this.$store.dispatch("countDaysToDelivered", {
          company: this.company,
        });
        this.orders = this.getDaysToDelivered;
        console.log(this.orders);
      } catch (err) {
        alert(err);
      } finally {
        this.loading = false;
      }
    },
    splitDate(date) {
      let split = date.split("T");
      return split[0];
    },
    averageDaysReceiveToExpedition() {
      const total = this.orders.reduce(
        (accumulator, order) => accumulator + Number(order.receiveToExit),
        0
      );
      console.log(total);
      const average = (total / this.orders.length).toFixed(0);
      return average;
    },
    averageDaysExpeditionToReceived() {
      const total = this.orders.reduce(
        (accumulator, order) => accumulator + Number(order.exitToDeliver),
        0
      );
      console.log(total);
      const average = (total / this.orders.length).toFixed(0);
      return average;
    },
    averageTotalDays() {
      const total = this.orders.reduce(
        (accumulator, order) => accumulator + Number(order.totalDays),
        0
      );
      console.log(total);
      const average = (total / this.orders.length).toFixed(0);
      return average;
    },
  },
  async created() {
    console.log("AQUI");
    await this.getOrdersDays();
  },
  computed: {
    ...mapGetters(["getDaysToDelivered"]),
  },
};
</script>
<style scoped>
p {
  text-align: center;
}
</style>